/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'typeface-droid-serif';
import 'typeface-raleway';
import './src/styles/global.css';

import { NAVBAR_OFFSET, SECTION_PADDING } from './src/config';

const scrollToElement = require('scroll-to-element');

export const onRouteUpdate = ({ location }) => {
    const { hash } = location;
    if (hash) {
        scrollToElement(hash, {
            offset: NAVBAR_OFFSET + SECTION_PADDING, // header offset + section padding (py-8 or 32px)
            duration: 1000,
        });
    }
}